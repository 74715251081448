@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Removes outline for any component */
  .remove-outline {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }

  .bg-gradient-browntored {
    background: linear-gradient(
      to right,
      rgba(32, 46, 51, 0.8),
      rgba(68, 35, 40, 0.6),
      rgba(177, 1, 7, 0.4)
    );
  }
  .clip-gradient {
    clip-path: polygon(0 0, 98% 0, 100% 100%, 0% 100%);
  }

  .noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
  }
}

/* dm-sans-regular - latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/dm-sans-v6-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/dm-sans-v6-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/dm-sans-v6-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/dm-sans-v6-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/dm-sans-v6-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/dm-sans-v6-latin-regular.svg#DMSans") format("svg"); /* Legacy iOS */
}
/* dm-sans-italic - latin */
@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/dm-sans-v6-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/dm-sans-v6-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/dm-sans-v6-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/dm-sans-v6-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/dm-sans-v6-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/dm-sans-v6-latin-italic.svg#DMSans")
      format("svg"); /* Legacy iOS */
}
/* dm-sans-500 - latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/dm-sans-v6-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/dm-sans-v6-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/dm-sans-v6-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/dm-sans-v6-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/dm-sans-v6-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/dm-sans-v6-latin-500.svg#DMSans")
      format("svg"); /* Legacy iOS */
}
/* dm-sans-500italic - latin */
@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/dm-sans-v6-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/dm-sans-v6-latin-500italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/dm-sans-v6-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/dm-sans-v6-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/dm-sans-v6-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/dm-sans-v6-latin-500italic.svg#DMSans") format("svg"); /* Legacy iOS */
}
/* dm-sans-700 - latin */
@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/dm-sans-v6-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/dm-sans-v6-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/dm-sans-v6-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/dm-sans-v6-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/dm-sans-v6-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/dm-sans-v6-latin-700.svg#DMSans")
      format("svg"); /* Legacy iOS */
}
/* dm-sans-700italic - latin */
@font-face {
  font-family: "DM Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/dm-sans-v6-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/dm-sans-v6-latin-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/dm-sans-v6-latin-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/dm-sans-v6-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/dm-sans-v6-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/dm-sans-v6-latin-700italic.svg#DMSans") format("svg"); /* Legacy iOS */
}
/* dm-serif-display-regular - latin */
@font-face {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/dm-serif-display-v4-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./fonts/dm-serif-display-v4-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/dm-serif-display-v4-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./fonts/dm-serif-display-v4-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("./fonts/dm-serif-display-v4-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/dm-serif-display-v4-latin-regular.svg#DMSerifDisplay")
      format("svg"); /* Legacy iOS */
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
